export const authentication_msgs = {
    APP_NAME: 'REV-FUEL',

    EMAIL_REQUIRED_MESSAGE: 'Email is required',
    EMAIL_INVALID_MESSAGE: 'Email is invalid',

    ERROR_ALERT_TITLE: 'Alert : ',


    PASSWORD_REQUIRED_MESSAGE: 'Password is required',
    PASSWORD_MIN_LENGTH_MESSAGE: 'Password must be at least 8 characters',
    PASSWORD_MAX_LENGTH_MESSAGE: 'Password must not exceed 20 characters',
    PASSWORD_PATTERN_MESSAGE: 'Enter strong password',
    PASSWORD_PATTERN_INFO_ALERT_MSG: 'Password must have minimum 8 characters, 1 uppercase, 1 numeric and 1 special character.',

    CONFIRM_PASS_REQ_MSG: 'Confirm password is required',
    CONFIRM_PASS_MISS_MATCH_MSG: 'Confirm password does not match',

    // Authentication Routes
    LOGIN_ROUTE: '/auth/login',
    FORGOT_PASS_ROUTE: '/auth/forgot-password',
    CREATE_NEW_PASS_ROUTE: '/auth/reset-password',

}