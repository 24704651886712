import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService:AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean| UrlTree> {

    /*
      No need to check user api in every route change
      we will do that once per session. though this
      gaurd is not doing anything useful as of now,
      but we can add role based restrictions here later.
    */

    let requiredRole = "ADMIN"
    if(state.url.startsWith("/customer")) {
      requiredRole = "CUSTOMER"
    } //more roles


    const currentuser = this.authService.currentUser
    const isRequiredRolePresent = currentuser.roles.includes(requiredRole)

    if(isRequiredRolePresent) {
      return of(isRequiredRolePresent);
    }

    return of(this.router.createUrlTree(['/auth/login']))
  }
}
