<mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
    [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
    class="menu-list-item ">
    <span class="nav-item ">
        <span class="lable-txt">
            <img *ngIf="depth==0" src="{{item.iconName}}" matListItemIcon class="menuIcon white_icon" alt="Image">
            <img *ngIf="depth==0" src="{{item.iconName.split('.png')[0] + '_blue.png' }}" matListItemIcon
                class="menuIcon blue-icon" alt="Image">
            <img *ngIf="depth==0" src="{{item.iconName.split('.png')[0] + '_green.png' }}" matListItemIcon
                class="menuIcon green-icon" alt="Image">
     <span class="lable-txt-para">{{item.displayName}}
    <span fxFlex *ngIf="item.children && item.children.length">
        <span fxFlex></span>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" matListItemIcon>
            expand_more
        </mat-icon>
    </span></span></span></span>

</mat-list-item>
<div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
    </app-menu-list-item>
</div>