import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Logger } from '../services/logger/logger.service';
import { LoaderService } from '../services/loader/loader.service';

export const skipLoader = 'skipInkerceptorLoader';
const log = new Logger('APIHandlerInterceptor');

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers && request.headers.has(skipLoader)) {
      this.loaderService.isCheckingDomain.next(true);
      request.headers.delete(skipLoader);
    } else {
      this.loaderService.show();
    }

    if (!environment.production) {
      // Log available on dev mode
      log.info(request);
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.loaderService.isCheckingDomain.next(false);
        this.loaderService.hide();
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !environment.production) {
          log.info(event);
        }
        return event;
      })
    );
  }
}
