export const pagination = { currentPage : 0, itemsPerPage : 10, totalElements :0, sizeOptions: [10,25,50,100] }

export const ROLES = {
    SUPER_ADMIN : { key : "SUPER_ADMIN",role: "ROLE_SUPER_ADMIN", prefix: "/admin" } ,
    ADMIN : { key : "ADMIN",role: "ROLE_ADMIN", prefix: "/admin" } ,
    CUSTOMER : { key : "CUSTOMER",role: "ROLE_CUSTOMER", prefix: "/customer" },
    CUSTOMER_STAFF : { key : "CUSTOMER_STAFF",role: "ROLE_CUSTOMER_STAFF", prefix: "/staff" }
  }

export enum PERMISSIONS {
    FULL_ACCESS = 'full_access',
    CUSTOMERS = 'customer_management',
    CARDS = 'card_management',
    TRANSACTIONS = 'transaction_management',
    INVOICES = 'invoice_management',
    USERS = 'user_management',
    REPORTS = 'reports',
    READ_ONLY = 'read_only',
}

export const permissions: { roles: string[], key: string, value: string, sub_permissions?: any[] }[] = [
    { roles: [ROLES.ADMIN.role, ROLES.CUSTOMER_STAFF.role], key : PERMISSIONS.CARDS, value: "Card Management" },

  {
    roles: [ROLES.CUSTOMER_STAFF.role], key: PERMISSIONS.REPORTS, value: "Reports",
    sub_permissions: [
      {key: "grouped_transactions_report", value: "Reports - Grouped Transactions"},
      {key: "detailed_transactions_report", value: "Reports - Detailed Transactions"},
      {key: "fuel_export_report", value: "Reports - Fuel Export"},
      {key: "fuel_transactions_report", value: "Reports - Fuel Transactions"},
    ]
  },

  {
    roles: [ROLES.ADMIN.role], key: PERMISSIONS.REPORTS, value: "Reports",
    sub_permissions: [
      {key: "total_revenue_report", value: "Total Revenue Report"},
      {key: "card_report", value: "Card Report"},
      {key: "fuel_prices_report", value: "Fuel Prices"},
    ]
  },

  {
    roles: [ROLES.ADMIN.role], key: PERMISSIONS.CUSTOMERS, value: "Customer Management",
    sub_permissions: [
      {key: "driver_management", value: "Driver Management"},
      {key: "single_customer_rate_plans", value: "Rate Plans"},
      {key: "single_customer_cards", value: "Cards"},
      {key: "single_customer_transactions", value: "Transactions"},
      {key: "single_customer_invoices", value: "Invoices"},
      {key: "grouped_detailed_transactions_report", value: "Reports - Grouped Transactions"},
      {key: "detailed_transactions_report", value: "Reports - Detailed Transactions"},
      {key: "fuel_export_report", value: "Reports - Fuel Export"},
      {key: "fuel_transactions_report", value: "Reports - Fuel Transactions"},
      {key: "single_customer_fuel_prices_report", value: "Reports - Fuel Prices"},
      {key: "single_customer_legal", value: "Legal"},
      {key: "single_customer_users", value: "User management"},
    ]
  },

    { roles: [ROLES.ADMIN.role, ROLES.CUSTOMER_STAFF.role], key : PERMISSIONS.TRANSACTIONS, value:  "Transaction Management" },
    { roles: [ROLES.ADMIN.role, ROLES.CUSTOMER_STAFF.role], key : PERMISSIONS.INVOICES, value:  "Invoice Management" },
    { roles: [ROLES.ADMIN.role], key : PERMISSIONS.USERS, value:  "User Management" },
]

export function toUTCDate(date : Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().slice(0, 10)
}

export function currentDateUTC() {
    const date = new Date()
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().slice(0, 10)
}

export const cardTypes = {
    'COMDATA' : 'Comdata',
    'ULTRAMAR' : 'Ultramar',
    // 'WEX' : 'Wex'
}

export const cardStatus = {
    'ACTIVE' : 'Active',
    'BLOCKED' : 'Blocked',
    'LOST' : 'Lost',
    //'DELETED' : 'Deleted'
}

export const transactionStatus = {
    "PROCESSED": "PROCESSED",
    "INVOICED": "INVOICED",
    "OPEN": "OPEN",
    "CLOSED": "CLOSED",
    "REJECTED": "REJECTED",
    "FAILED": "FAILED"
}

export enum invoiceStatus {
    PENDING_APPROVAL = "Pending Approval",
    OPEN = "Open",//Approved
    CLOSED = "Closed",//Paid
    REJECTED = "Rejected"
}

export const pricingOptions = {
    'COST_PLUS' : 'COST_PLUS',
    'RETAIL_MINUS' : 'RETAIL_MINUS'
}

export const vendors = {
    'TA_PETRO' : 'TA_PETRO',
    'IMPERIAL_OIL' : 'IMPERIAL_OIL',
    'FLEET7' : 'FLEET7',
    'AMBEST' : 'AMBEST',
    'CASEY' : 'CASEY',
    'ULTRAMAR' : 'ULTRAMAR'
}

export const currencies = [ "USD", "CAD" ]
export const states = [
     "AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY", //for Canada
     "AB","BC","MB","NB","NL","NS","NT","NU","ON","PE","QC","SK","YT" //for US
]

export const ROUTES = {

    CARD_NEW : '/card/add',
    CARD_VIEW : '/card/view',
    CARD_UPDATE : '/card/edit',
    CARDS_VIEW : '/card',

    USER_NEW : '/user/add',
    USER_EDIT : '/user/edit',

    CUSTOMER_NEW : '/customer/add',
    CUSTOMER_VIEW : '/customer/view',
    CUSTOMER_EDIT : '/customer/edit',
    CUSTOMER_SELF_EDIT : '/profile/update',
    CUSTOMERS_VIEW : '/customer',

    DRIVER_NEW : '/driver/add',
    DRIVER_VIEW : '/driver/view/',
    DRIVER_EDIT : '/driver/edit/',
    DRIVERS_VIEW : '/driver',


    RATE_PLAN_ADD : '/rate-plan/add',
    RATE_PLAN_TERMINATE : '/rate-plan/terminate_plan',
    RATE_PLAN_VIEW : '/rate-plan/view',
    RATE_PLAN_EDIT : '/rate-plan/edit',

    INVOICE_CREATE : '/invoices/create',
    INVOICE_VIEW : '/invoices/view',

}
