import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { authentication_msgs } from '../../data/text-msg';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  public title: string = authentication_msgs.ERROR_ALERT_TITLE;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { status: string, title: string, msg: string; }) { }

}
