import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SuccessAlertComponent } from '../../data-alert/success-alert/success-alert.component';
import { ErrorDialogComponent } from '../../data-alert/error-dialog/error-dialog.component';
// import { ErrorDialogComponent } from '../../dialog-alerts/error-dialog/error-dialog.component';
// import { PasswordInfoComponent } from '../../dialog-alerts/password-info/password-info.component';

export interface SuccessDialogConfig {
  title?: string;
  action?: 'Edit' | 'Delete';
  content?: string;
  msg?: string;
  onCloseNavUrl?: string;
}
export interface ErrorDialogConfig {
  title?: string;
  content?: string;
  msg?: string;
  onCloseNavUrl?: string;
}

export interface SnackPosition {
  horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right',
  verticalPosition?: 'bottom' | 'top',
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public isDialogOpen: Boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) { }
  //------------------------ MatSnackBar -----------------------------
  openSnackBar(message: string = 'Something went wrong', action: string, panelClass: string = '', snackPosition: SnackPosition = { verticalPosition: 'bottom', horizontalPosition: 'center' }) {
    if (this.snackBar._openedSnackBarRef) {
      this.snackBar._openedSnackBarRef._dismissAfter(0);
    }
    this.snackBar.open(message, action, {
      duration: 3000,
      ...snackPosition,
      panelClass: [panelClass]
    })
  }

  openSnackBarDummy(message: string = 'Something went wrong', action: string, panelClass: string = '', snackPosition: SnackPosition = { verticalPosition: 'bottom', horizontalPosition: 'center' }) {
    this.snackBar.open(message, action, {
      panelClass: [panelClass],
      ...snackPosition
    })
  }

 

  openSuccessAlertDialog(data: SuccessDialogConfig, enterAnimationDuration: string = '300ms', exitAnimationDuration: string = '250ms'): any {
    if (this.isDialogOpen) {
      return false;
    }
    console.log("Success Dialog Opened", this.isDialogOpen);
    this.isDialogOpen = true;
    const successAlertDialogRef = this.dialog.open(SuccessAlertComponent, {
      maxWidth: '500px',
      width: '90vw',
      disableClose: true,
      enterAnimationDuration,
      exitAnimationDuration,
      data: data,
    });

    successAlertDialogRef.afterClosed().subscribe(result => {
      console.log("Success Dialog Closed");
      this.isDialogOpen = false;
      if (data.onCloseNavUrl) {
        this.router.navigate([data.onCloseNavUrl]);
      }
    });
  }

  openErrorDialog(data: { status: string, title: string, msg: string; }, enterAnimationDuration: string = '300ms', exitAnimationDuration: string = '250ms'): any {
    if (this.isDialogOpen) {
      return false;
    }
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      maxWidth: '500px',
      width: '90vw',
      disableClose: true,
      enterAnimationDuration,
      exitAnimationDuration,
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
    });
  }

}
