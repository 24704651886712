import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) { }

  get(uri: string): Observable<any> {
    return this.http.get<any>(uri).pipe(
      tap(_ => this.log('Done')),
      catchError(this.handleError<any>(`get url - ${uri}`, []))
    );
  }

  post(uri: string, payload: any): Observable<any> {
    return this.http.post<any>(uri, payload).pipe(
      tap(_ => this.log('Done')),
      catchError(this.handleError<any>(`get url - ${uri}`, []))
    );
  }

  put(uri: string, payload: any): Observable<any> {
    return this.http.put<any>(uri, payload).pipe(
      tap(_ => this.log('Done')),
      catchError(this.handleError<any>(`get url - ${uri}`, []))
    );
  }

  delete(uri: string): Observable<any> {
    return this.http.delete<any>(uri).pipe(
      tap(_ => this.log('Done')),
      catchError(this.handleError<any>(`get url - ${uri}`, []))
    );
  }

  // getWithSkipLoader(uri: string): Observable<any> {
  //   return this.http.get<any>(uri, { headers: InterceptorSkipLoader });
  // }

  // postWithSkipLoader(uri: string, payload: any): Observable<any> {
  //   return this.http.post<any>(uri, payload, { headers: InterceptorSkipLoader });
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T);
    };
  }

  private log(message: string) {
  }

}


