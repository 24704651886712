import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Logger } from './../services/logger/logger.service';
import { environment } from '../../../environments/environment';
import { MessageService } from '../services/message/message.service';

const log = new Logger('ErrorHandlerInterceptor');
/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService,
    ) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/ui-bff/auth/user')) {
      // If it matches, just forward the request without intercepting
      return next.handle(request);
    }
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        if (!environment.production) {
          // Log available on dev mode
          log.error('Request error', error);
        }
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = this.getServerErrorMessage(error);
        }

        // ------------- Alert -------------
        let errAlertTitle = (error.error && error.error.message) ? error.error.message : "ERROR...";
        let errAlertDesc = (error.error && error.error.error_desc) ? error.error.error_desc : errorMessage;
        this.messageService.openErrorDialog({ title: errAlertTitle, msg: errAlertDesc, status: `${error.status}` });
        return throwError(errorMessage);
      })
    );
  }

  //  Avoid to pass the real errors to client
   getServerErrorMessage(error: HttpErrorResponse): string {
    let errMsg = (error.error && error.error.message) ? error.error.message : "ERROR...";
    let errDesc = (error.error && error.error.error_desc) ? `, ${error.error.error_desc}` : "Something went wrong";
    switch (error.status) {
      case 400: {
        return `Bad Request: ${errMsg}${errDesc}`;
      }
      case 401: {
        return `Unauthorized: ${errMsg}${errDesc}`;
      }
      case 403: {
        return `Forbidden: ${errMsg}${errDesc}`;
      }
      case 404: {
        return `Not Found: ${errMsg}${errDesc}`;
      }
      case 422: {
        return `Unprocessable Entity: ${errMsg}${errDesc}`;
      }
      case 500: {
        return `Internal Server Error: ${errMsg}${errDesc}`;
      }
      default: {
        return `Unknown Server Error: ${errMsg}${errDesc}`;
      }
    }
  }
}
