import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderMainComponent } from './header-main/header-main.component';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../core/material.module';
import { LayoutComponent } from './layout/layout.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { RouterModule } from '@angular/router';

// Components
const COMPONENTS = [
  FooterComponent,
  HeaderMainComponent, LayoutComponent, MenuListItemComponent
]

@NgModule({
  declarations: [...COMPONENTS],
    imports: [
        CommonModule,
        CoreModule,
        MaterialModule,
        RouterModule
    ],
  exports: [...COMPONENTS]
})
export class LayoutModule { }
