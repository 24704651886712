import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  public isLoading: BehaviorSubject<boolean> = this.loaderService.isLoading$;
  constructor(private loaderService: LoaderService) { }

}
