import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { authentication_msgs } from 'src/app/core/data/text-msg';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent {
  @Output() menuClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private router: Router,
    ) { }
  signout() {
    
    console.log('logout')
  }

  menuClick() {
    this.menuClicked.emit(true);
  }
}
