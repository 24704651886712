<!-- New Design -->
<p class="modal-head"> <img src="./assets/images/checked.png" alt="Image" class="checked">Success</p>
<mat-dialog-content class="mat-typography modal-content">
    <img src="./assets/images/check-mark.png" alt="Image" class="checked-mark">
    <p class="head-msg">Successfully...
        <!-- <ng-container *ngIf="data.title"><br>{{data.title}}</ng-container> -->
    </p>
    <p *ngIf="data.content" class="text-popup">{{data.content}}</p>
    <p *ngIf="data.msg" class="text-popup">{{data.msg}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" class="cross" cdkFocusInitial>OK</button>
</mat-dialog-actions>
