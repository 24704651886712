import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { MessageService } from '../services/message/message.service';
import { PERMISSIONS, ROLES } from '../data/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService:AuthService,
    private messageService: MessageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean| UrlTree> {

    return of(true);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean| UrlTree> {

    /*
      No need to check user api in every route change
      we will do that once per session. though this
      gaurd is not doing anything useful as of now,
      but we can add role based restrictions here later.
    */

    let requiredRole = "ADMIN"
    if(state.url.startsWith("/customer")) {
      requiredRole = "CUSTOMER"
    } else if(state.url.startsWith("/staff")) {
      requiredRole = "CUSTOMER_STAFF"
    } //more roles


    const currentuser = this.authService.currentUser
    const isRequiredRolePresent = currentuser.roles.includes(ROLES.SUPER_ADMIN.key) || currentuser.roles.includes(requiredRole)

    /*
      For some routes we can specify an array of permitted roles which will
      be checked here. Some certain pages are available to only few role
      like card creation can be done by group admin only, so we can specify
      permitted roles in router 'data' with key 'roles' as Array<string>
    */
    if(route.data['roles']) {
      const neededRole = route.data['roles'] as Array<string>
      if(!neededRole.includes(this.authService.getRole())) {
        this.messageService.openSnackBar('Your account role is not permitted to perform this action.','OK','error-snack');
        return of(this.router.createUrlTree(['/auth/unauthorised'], { queryParams : { reason : 'role_not_allowed' }}))
      }
    }

    //check permissions for [ADMIN|CUSTOMER_STAFF] and allow/deny based on allowed permissions
    if(this.authService.is(ROLES.CUSTOMER_STAFF.key) || this.authService.is(ROLES.ADMIN.key)) {
      if(route.data['permissions'] && route.url.length > 0) { // on reload route.url will likely be 0 as 'canActivateChild' event trigger twice
        const permissions = route.data['permissions'] as Array<string>

        const isPermitted = permissions.some(permission => this.authService.hasPermission(permission))
        if(!isPermitted) {
          this.messageService.openSnackBar('Access to this page is restricted.','OK','error-snack');
          return of(this.router.createUrlTree(['/auth/unauthorised'], { queryParams : { reason : 'no_permission' }}))
        }
      }

      /*
        If readonly is false in route - it means that current route should be checked
        for permission [view_only]. If view_only permission is present in current user [received from backend]
        this means current user can not access readonly=false pages, such pages are for creation/updation
        of resources. In case readonly key is not present in route - it means the route is readonly (every
        route where readonly is not specified in readonly=true)
      */

      if(route.data['readonly']==false) {
        if(this.authService.hasPermission(PERMISSIONS.READ_ONLY)) {
          this.messageService.openSnackBar('Your permissions are restricted to view-only.','OK','error-snack');
          return of(this.router.createUrlTree(['/auth/unauthorised'], { queryParams : { reason : 'read_only_permission' }}))
        }
      }
    }

    if(isRequiredRolePresent) {
      return of(isRequiredRolePresent);
    }

    return of(this.router.createUrlTree(['/']))
  }
}
