import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavService } from 'src/app/core/services/nav.service';
import { NavMenu } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item!: NavMenu;
  @Input() depth: number = 0;

  constructor(
    public router: Router,
    public navService: NavService,
    public authService: AuthService,
    ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  staticRoutes = ['my-profile','settings','logout']
  onItemSelected(item: NavMenu) {
    if(item.route && this.staticRoutes.includes(item.route)) {
      this.ifStaticMenu(item)
    } else if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
    } else if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

   
  ifStaticMenu(item: NavMenu) {    
    switch(item.route) {
      case "my-profile": {
        this.router.navigate([this.authService.getRoleBasedURL("/my-profile")]);
        break;
      }

      case "settings" : {
        
        break;
      }

      case "logout" : {
        this.authService.logout()
        break;
      }
    }
  }

  isRouteActive(item: NavMenu) {
    return item.route && this.router.isActive(item.route, true)
  }
}
