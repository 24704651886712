import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },//login page will redirect to appropriate home page based on role

  { path: 'admin', loadChildren: () => import('./admin-role/admin-role.module').then(m => m.AdminRoleModule), canActivate: [AuthGuard] },
  { path: 'customer', loadChildren: () => import('./customer-role/customer-role.module').then(m => m.CustomerRoleModule), canActivate: [AuthGuard] },

  { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)},
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },//login page will redirect to appropriate home page based on role
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
