<p class="modal-head"> <img src="./assets/images/warning.png" alt="Image"
    class="checked">Assign card</p>
<mat-dialog-content class="mat-typography modal-content">
<img src="./assets/images/warning-red.png" alt="Image" class="checked-mark">
<p class="head-msg">Are you Sure?</p>
<p class="text-popup">Want to assign selected customer ID ?</p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="delete-btn">
<button mat-button color="warn" [mat-dialog-close]="false" class="cancel-btn">Cancel</button>
<button mat-button color="primary" [mat-dialog-close]="true">Assign</button>
</mat-dialog-actions>
