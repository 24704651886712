import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarNavHelperService } from './services/sidebar-nav-helper.service';
import { NavService } from './services/nav.service';
import { MaterialModule } from './material.module';
import { HttpService } from './services/http/http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PipesModule } from './pipes/pipe.module';
import { SuccessAlertComponent } from './data-alert/success-alert/success-alert.component';
import { MessageService } from './services/message/message.service';
import { LoaderComponent } from './components/loader/loader.component';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { ErrorDialogComponent } from './data-alert/error-dialog/error-dialog.component';
import { AssignCardAlertComponent } from './data-alert/assign-card-alert/assign-card-alert.component';

// Services
const SERVICES = [
  SidebarNavHelperService,
  NavService,
  HttpService,
  MessageService
]

// Modules
const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  PipesModule
]

@NgModule({
  declarations: [
    SuccessAlertComponent,
    LoaderComponent,
    ErrorDialogComponent,
    AssignCardAlertComponent


  ],
  imports: [...MODULES, MaterialModule],
  exports: [...MODULES,
    LoaderComponent,
  ],
  providers: [...SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    }],
  entryComponents: [
    SuccessAlertComponent]

})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...SERVICES,
      ],
    };
  }

}
