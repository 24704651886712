import { Component, Inject, Optional } from '@angular/core';
import { SuccessDialogConfig } from '../../services/message/message.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-alert',
  templateUrl: './success-alert.component.html',
  styleUrls: ['./success-alert.component.scss']
})
export class SuccessAlertComponent {
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: SuccessDialogConfig) { }

}
