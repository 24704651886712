export interface NavMenu {
  label: string,
  matIcon : string,
  route?: string,
  roles: { role : string, data : { route: string, permission? :string } }[],
  permissions?: string[],
  badgeData?: string | number,
  isSubMenu?: boolean,
  children?: NavMenu[]
}

export interface RolesPermissions {
  currentRole:string
  admin:boolean
  admin_only : boolean
  super_admin:boolean
  customer_only:boolean
  customer_staff:boolean
  customer:boolean
}

export interface LoginOptionDto {
  label: string;
  loginUri: string;
  isSameAuthority: boolean;
}

export class UserDto {
  static readonly ANONYMOUS = new UserDto('', [], false,0,[]);

  readonly username: string;
  readonly roles: string[];
  readonly permissions?: string[];
  readonly authenticated: boolean;
  readonly userId: number;

  constructor(username: string,roles:string[],authenticated:boolean,userId:number,permissions:string[]) {
    this.roles = roles
    this.username = username
    this.authenticated = authenticated
    this.userId = userId
    this.permissions = permissions
  }

}

export interface PaginatedResponse<T> {
  totalElements:number;
  content : T[];
  pageable? : { pageNumber:number,pageSize:number }
}

export interface User {
  id: string
  createdTimestamp : number
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: string;
  attributes: any;
  isEnabled: boolean;
  role: string;
  permissions: string[];
}

export interface Customer {
  id: number
  factorSoftCustomerId: string;
  factorSoftDebtorId: string;
  motorCarrierNumber: string;
  usDotNumber: string;
  name: string;
  secretKey: string;
  notes: string;
  status: string;
  overAllLimit: number;
  cashAdvanceLimit: number;
  dieselLimit: number;
  reeferLimit: number;
  defLimit: number;

  email: string;
  priceFileEmail: string;
  billingEmail : string;
  invoiceEmail : string;
  phoneNumber: string;

  tradeName: string;
  businessHstNumber: string;
  numberOfTrucks : number;
  businessNumber : string;
  monthlySalesVolume: number;

  previousFuelProvider: string;
  website : string;


  monthlyFuelVolumeLitres: number;
  monthlyFuelVolumeGallons: number;
  monthlyFuelVolumeCdn : number;
  monthlyFuelVolumeUsd: number;

  billingAddress :CustomerAddress
  businessAddress :CustomerAddress
  cadBankAccount : CustomerBank
  usdBankAccount : CustomerBank
  businessOwners : CustomerBusinessOwners[]
}

export interface CustomerAddress {
  streetAddress: string;
  unit: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  addressType: string;
}

export interface CustomerBank {
  currency: string;
  institutionNumber: string;
  transitNumber: string;
  accountNumber: string;
  accountType: string;
  accountHolderName: string;
  accountHolderAddress: CustomerAddress;
}

export interface CustomerBusinessOwners {
  ownerFullName: string;
  percentageOwned: number;
  driversLicenseNumber: string;
  sin: string;
  phoneNumber : {
    phoneNumber:string,
    phoneType :string
  }
  address: CustomerAddress;
}

export interface AssignData {
    name: string | number;
    customerId: string | number;
    status: string | number;
    isChecked: boolean;
    disabled: boolean;
  }

export interface Card {
  id: number;
  bank: string;
  number: string | number;
  customerId: string | number;
  driverId: number;
  status: string;
  name: string;
}

export interface Paged {
  page:number,
  size:number
}

export interface CustomerSearchParam extends Paged{
  name?:number,
  factorSoftCustomerId?:string,
  customerStatus?:string,
}

export interface CardSearchParam {
  bank?:string,
  status?:string,
  customerId?:number,
  cardNumber?:number,
  last4CardNumber?:number,
  driverId?:number,
  page:number,
  size:number
}

export interface TransactionSearchParam {
  startDate?:string,
  endDate?:string,
  bank?:string,
  status?:string,
  customerId?:number,
  cardNumber?:number,
  driverId?:number[],
  page:number,
  size:number
}

export interface InvoiceSearchParam {
  page:number,
  propertySize:number,
  startDate?:string,
  endDate?:string,
  dueDate?:string,
  status?:string,
  customerIds?:number[],
}

export interface RatePlanSearchParam extends Paged {
  customerId?:number,
  vendorId?:string
}

export interface DriverSearchParam {
  customerId?:number,
  searchQuery?:string,
  page:number,
  size:number
}

export interface FuelPricesSearchParam {
  customerId?:number,
  vendor:string,
  date:String,
  siteCode?:String
  pricingMethod?:String
  pricingValue?:number
}

export interface Customer {
  id: number;
  name: string;
  email: string;
  overAllLimit: number;
  status: string;
}

export interface RatePlan {
  id: number;
  customerId: string | number;
  vendorId: string | number;
  startDate: string | number;
  endDate: string | number;
  pricingMethod: string | number;
  pricingValue: string | number;
  rebateValue : number
  sendPriceFile : boolean;
}

export interface CardSettings {
  customerId: string,
  overallLimit: number,
  cashAdvanceLimit: number,
  diesalLimit: number,
  reeferLimit: number,
  defLimit: number,
  purchaseLimit: number,
  chekLimit: number,
  tCheckLimit: number,
  loadCashLimit: number,
  allowLoadCashAccess: boolean,
  allowUltramarCardAccess: boolean,
  vehicleNonMandatory: boolean,
  enableCheckIssueAlertAdmin: boolean
}

export interface UltramarCardLimits {
  cashAdvanceLimit: number,
  dieselLimit: number,
  reeferLimit: number,
  defLimit: number
}

export interface Driver {
  id:number,
  customerId:number,
  firstName:string,
  creationDate:Date,
  lastName:string,
  licenseNumber:string,
  licenseState:string,
  trailerNumber:string,
}

export interface Transaction {
  transactionId: number,
  customerId: number,
  transactionDateTime: string,
  transactionTotal: number,
  transactionCurrency: string,
  transactionStatus: string,
}

export interface CreditTransaction extends Transaction {
  id: number,
  cardId: number,
  cardNumber: string,
  ratePlanId: string,
  bank : string
  bankTransactionId:string,
  bankTotal: number,
  bankCurrency: string,
  truckStopCode: string,
  truckStopVendor: string,
  truckStopName: string,
  truckStopCity: string,
  truckStopState: string,
  driverId: number,
  driverName: string
  customerName:number
  driverLicenseNumber: string
  driverLicenseState: string
  trailerNumber: string
}

export interface RebateTransaction extends Transaction {
  
  rebateType: number,
  creditTransactionId: string,
  description: string,
  transactionDetails?: RebateTransactionDetails[]
  rebateTransactionDetails? : RebateTransactionDetails[]
}

export interface RebateTransactionDetails {
  quantity: number,
  unitPrice: number,
  taxRate: number,
  amount: number,
  productId: number,
  productName:string,
  productType: string
  productQuantityUnit: string,
  rebateTransactionId: number,
  rebateTransactionDetailId: number,
  creditTransactionDetailId: number,
  creditTransactionId: number
}

export interface TransactionProduct {
  productName:string,
  productType: string
  productQuantity: number,
  productQuantityUnit: string,
  taxRate: number,
  totalAmount: number,
  surchargeAmount: number,
  discrepancyAmount: number,
}

export interface ReportTransactionGroupedSearchParam {
  customerId : number
  groupBy : string
  orderBy : string
  startDate : string
  endDate : string

  cardType : string
  cardNumber : string

  driverId : number
  driverName : string
  vehicleNumber : string

  currency : string
  state : string
  city : string
}

export interface FuelTransactionSearchParam {
  customerId : number
  fuels : string[]
  orderBy : string
  startDate : string
  endDate : string

  cardType : string
  cardNumber : string

  driverId : number
  driverName : string
  vehicleNumber : string

  currency : string
  state : string
  city : string
}

export interface CardsReportSearchParam {
  cardType : string
  cardNumber : string
  cardStatus : string

  driverId : string
  firstName : string
  lastName : number

  orderBy : string
  vehicleNumber : string
}

export interface Invoice {
  currency: string,
  customerId:number,
  customerName: string,

  startDate: string,
  dueDate: string,
  endDate: number,

  invoiceId: number,
  invoiceNumber: string,
  invoiceDate: string,
  invoiceStatus:string,
  invoiceType: string,
  totalAmount: number,
  //transactions: InvoiceTransactions[]
  //rebateTransactions : RebateTransaction[]
}

export interface InvoiceCreditTransactions {
  cardId:number
  cardNumber:string
  customerId:number
  driverId:number
  ratePlanId:number
  bank:string
  driverLicenseNumber:string
  driverLicenseState:string
  driverName:string
  trailerNumber:string
  transactionCurrency:string
  transactionDateTime:string
  truckStopCity:string
  truckStopCode:string
  truckStopName:string
  truckStopState:string
  truckStopVendor:string

  transactionId:number
  transactionTotal:number
}

export interface ReportFuelExportSearchParam {
  customerId : number
  startDate : string
  endDate : string

  currency : string
  cardType : string

}

export interface TotalRevenueReportSearchParam {
  startDate : string
  endDate : string
}
