<!-- Error Message -->
<p class="modal-head"> <img src="./assets/images/warning.png" class="checked" alt="Image">
    Something went wrong
</p>
<mat-dialog-content class="mat-typography modal-content">
    <img src="./assets/images/warning-red.png" class="checked-mark" alt="Image">
    <p class="head-msg"><ng-container *ngIf="data.title">{{data.title}}</ng-container></p>
    <p class="text-popup" *ngIf="data.msg">{{data.msg}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" class="cross" cdkFocusInitial>OK</button>
</mat-dialog-actions>
