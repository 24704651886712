import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../environments/environment";

export interface LoginOptionDto {
  label: string;
  loginUri: string;
  isSameAuthority: boolean;
}

export class UserDto {
  static readonly ANONYMOUS = new UserDto('');
  readonly username: string;

  constructor(username: string) {
    this.username = username
  }

  get isAuthenticated(): boolean {
    return this.username !== '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user$ = new BehaviorSubject<UserDto>(UserDto.ANONYMOUS);

  constructor(private httpClient: HttpClient) {
  }

  getLoginOptions(): Observable<LoginOptionDto[]> {
    return this.httpClient.get<LoginOptionDto[]>('/ui-bff/auth/login-options');
  }

  getCurrentUser(): Observable<UserDto> {
    return this.httpClient.get<UserDto>('/ui-bff/auth/user').pipe(
      map(response => new UserDto(response.username)),
      catchError(() => of(UserDto.ANONYMOUS))
    );
  }

  logout(): void {
    this.httpClient.post('/ui-bff/logout', {}, {
      headers: {'X-POST-LOGOUT-SUCCESS-URI': environment.baseUrl},
      observe: 'response'
    }).subscribe(resp => {
      const logoutUri = resp.headers.get('Location');
      if (logoutUri) {
        window.location.href = logoutUri;
      }
    }, () => {
    }, () => this.user$.next(UserDto.ANONYMOUS));
  }
}
