<mat-toolbar color="primary" class="my-toolbar">
    <button mat-icon-button (click)="menuClick()" class="my-icon" aria-label="Icon-button with menu icon">
        <mat-icon>menu</mat-icon>
    </button>
    <section>
        <div class="my-button-row">
            <button mat-button [routerLink]="['/home']">Home</button>
            <button mat-button [routerLink]="['/home']">Menu-1</button>
            <button mat-button [routerLink]="['/home']">Menu-2</button>
            <button mat-button [routerLink]="['/home']">Menu-3</button>
            <button mat-button [routerLink]="['/home']">Menu-4</button>
        </div>
    </section>
    <span class="my-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="usermenu" aria-label="menu button">
        <mat-icon>more_vert</mat-icon>
    </button>
</mat-toolbar>

<mat-menu #usermenu="matMenu">
    <button mat-menu-item [routerLink]="['my-profile']">
        <mat-icon>account_circle</mat-icon>
        <span>My Profile</span>
    </button>
    <button mat-menu-item disabled>
        <mat-icon>key</mat-icon>
        <span>Change Password</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="signout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
    </button>
</mat-menu>