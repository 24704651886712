import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaskFullPipe } from './mask-full.pipes';
@NgModule({
  declarations: [MaskFullPipe],
  imports: [
    CommonModule
  ],
  exports: [MaskFullPipe]
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [

      ],
    };
  }
}
