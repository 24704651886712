import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maskfull' })
export class MaskFullPipe implements PipeTransform {

  transform(input: string): string {

    if (input && input.length) {
        input = 'X'.repeat(input.length) 
        return input 
    } else {
        return input;
    }
   
  }
}
