import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCheckingDomain: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSignUpLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public resetProgressBar: EventEmitter<any> = new EventEmitter<boolean>(false);
  constructor() { }
  show() {
    console.log("Show Loader");
    this.isLoading$.next(true);
  }
  hide() {
    console.log("Hide Loader");
    this.isLoading$.next(false);
  }
  showSignUpProgress() {
    console.log("Show SignUpProgress");
    this.isSignUpLoading$.next(true);
    this.resetProgressBar.emit(true);
  }
  hideSignUpProgress() {
    console.log("Hide SignUpProgress");
    this.isSignUpLoading$.next(false);
    this.resetProgressBar.emit(false);
  }
}
