<mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
    [ngClass]="{'active': isRouteActive(item), 'expanded': expanded}"
    class="menu-list-item ">
    <span class="nav-item ">
        <span class="lable-txt">
            
            <mat-icon *ngIf="isRouteActive(item)" class="active-nav-mat-icon">chevron_right</mat-icon>
            <mat-icon [ngClass]="{ 'active-menu-mat-icon' : isRouteActive(item), 'inactive-menu-mat-icon': !isRouteActive(item) }">{{ item.matIcon }}</mat-icon>
                <!-- 
                    maticon is used instead of static images  - this portion will be removed later
                <img *ngIf="depth==0" src="{{item.icon}}" matListItemIcon class="menuIcon white_icon" alt="Image">
                <img *ngIf="depth==0" src="{{item.icon.split('.png')[0] + '_blue.png' }}" matListItemIcon class="menuIcon blue-icon" alt="Image">
                <img *ngIf="depth==0" src="{{item.icon.split('.png')[0] + '_green.png' }}" matListItemIcon class="menuIcon green-icon" alt="Image">
                -->
            <span class="lable-txt-para">{{item.label}}
                <span fxFlex *ngIf="item.children && item.children.length">
                    <span fxFlex></span>
                    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" matListItemIcon>
                        expand_more
                    </mat-icon>
                </span>
            </span>
        </span>
    </span>
</mat-list-item>

<div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
    </app-menu-list-item>
</div>