import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NavMenu } from '../data/data.modal';

@Injectable({
  providedIn: 'root'
})
export class SidebarNavHelperService {

  constructor() {
  }

  public itemType(item: { divider?: any; title?: any; children?: any; label?: any; }) {
    if (item.divider) {
      return 'divider';
    } else if (item.title) {
      return 'title';
    } else if (item.children) {
      return 'dropdown';
    } else if (item.label) {
      return 'label';
    } else if (!Object.keys(item).length) {
      return 'empty';
    } else {
      return 'link';
    }
  }

  public isActive(router: { isActive: (arg0: any, arg1: boolean) => any; }, item: { url: any; }) {
    return router.isActive(item.url, false);
  }

  public hasBadge = (item: { badge: any; }) => Boolean(item.badge);
  public hasIcon = (item: { icon: any; }) => Boolean(item.icon);

  public getIconClass(item: { icon: any; }) {
    const classes = {
      'nav-icon': true
    };
    if (this.hasIcon(item)) {
      const icon = item.icon;
      // classes[icon] = this.hasIcon(item);
    }
    return classes;
  }

  hasPermission(item: NavMenu) {
    let profile = { role: 'admin', visibleMenus: ['home', 'uma1'] }
    return new Observable(observer => {
      if (item.permissions) {
        // console.log(profile.role)
        if (item.permissions === 'home' && (profile.role !== 'role_bank')) { return observer.next(true); }
        if (item.permissions === 'profile' && (profile.role != 'role_card_holder' && profile.role != 'role_bank')) { return observer.next(false); }
        // const permissionFound = profile.visibleMenus.find(x => x.toUpperCase() === item.permissions.toUpperCase());
        // return observer.next(!!permissionFound);
      } else {
        let permissionFound = true;
        if (item.isSubMenu) {
          // let _anyPermission = item.children.filter(x => x.permissions);
          // if (_anyPermission.length > 0) {
          //   // permissionFound = profile.visibleMenus.find(x => x.toUpperCase() === _anyPermission[0].permissions.toUpperCase());
          // }
          return observer.next(!!permissionFound);
        } else {
          return observer.next(permissionFound);
        }
      }
    });
  }

}
