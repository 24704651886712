import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { NavService } from 'src/app/core/services/nav.service';
import { AuthService } from "../../core/services/auth/auth.service";
import { NavMenu, UserDto } from 'src/app/core/models';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  @Input() navMenu: NavMenu[] = [];
  public mobileQuery: MediaQueryList;
  public opened: boolean = true;
  public isSideNavOpen: boolean = true;
  private _mobileQueryListener: () => void;
  @ViewChild('snav') sidenav!: MatSidenav;
  public loggedInUser = {} as UserDto;


  staticMenu : NavMenu[] = [
    {
      label: 'Profile',
      route: 'my-profile',
      matIcon: 'person',
      roles: [ ]
    },
    {
      label: 'Settings',
      route: 'settings',
      matIcon: 'settings',
      roles: [ ]
    },
    {
      label: 'Logout',
      matIcon: 'logout',
      route: 'logout',
      roles: [ ]
    },
  ]
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private navService: NavService,
    private authService: AuthService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.whoAmI()
  }

  whoAmI() {
    this.loggedInUser = this.authService.currentUser;
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  signout() {
    this.authService.logout();
    // this.router.navigate([authentication_msgs.LOGIN_ROUTE]);
    console.log('logout-1')

  }

  ngAfterViewInit(): void {
    // -------- For Multilavel Menu -------------
    this.navService.appDrawer = this.sidenav;
  }

  routeToHome() {
    this.router.navigate(['/'])
  }

  menuIconClick(event: boolean) {
    this.isSideNavOpen = event;
  }
}

