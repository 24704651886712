import { Component } from '@angular/core';

@Component({
  selector: 'app-assign-card-alert',
  templateUrl: './assign-card-alert.component.html',
  styleUrls: ['./assign-card-alert.component.scss']
})
export class AssignCardAlertComponent {

}
